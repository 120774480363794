import { makeAPIRequest } from "./makeRequest";

export const UpdateLotLocation = (vin: string, stock: string, id: number, location: string) => {
    let config = {
        method: 'PUT',
        url: process.env.REACT_APP_SERVER_URL + 'Vehicle/EditLotLocation?dealerID=' + id + '&vin=' + vin + '&stockNo=' + stock + '&lotLocation=' + location,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}