import React, { useState, useEffect, useCallback } from "react";
import './styles.css'
import GetModal from "../api/getModal";
import { AlertsData } from './dashboard';

interface Props {
    onAlertChnage: AlertsData
}

const LabelConfig = ({onAlertChnage}: Props) => {
    const [dealID, setDealId] = useState('');
    const [dealName, setDealName] = useState('');
    const [contact, setContact] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [dealshipName, setDealShipName] = useState<any>();
    const [dealershipLabelId, setDealershipLabelID] = useState<number>();
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    let Did = sessionStorage.getItem('dealerId')

    const fetchData = useCallback(async () =>  {
        if(Did) {
            let res = await GetModal('GET','DealerShip/DMSCreate?dealerIdSel=' + Did + '-Label Config', '');
            const newIsEmpty = Object.entries(res).filter(([key, val]) => key !== "dealershipLabelId" && key !== "dealership" && val !== null).length === 0;
            setIsEmpty(newIsEmpty);
            setDealId(Did);
            setDealershipLabelID(res.dealershipLabelId);
            setDealShipName(res.dealership)
            setDealName(res.dealership.dealerShipName);
            setContact(res.contact);
            setPhone(res.phone);
            setEmail(res.email);
            setStreet(res.street);
            setCity(res.city);
            setState(res.state);
            setZip(res.zip);
        }
    }, [Did])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    const sendData = async () => {
        let res
        let data = {
            "dealerShipName": dealName,
            "dealershipLabelId": dealershipLabelId,
            "contact": contact,
            "phone": phone,
            "email": email,
            "street": street,
            "city":	city,
            "state": state,
            "zip":	zip,
            "dealership": dealshipName
        }

        if(isEmpty) {
            res = await GetModal('POST', 'DealerShip/LabelCreate?dealerIdSel=' + Did + '-DMSConnectivity', data)
            if(res === "Success") {
                sendResponse(0, 'Dealership label created & configured successfully', 'Success')
            } else {
                sendResponse(1, 'Failed to create & configure the Dealership label', 'Failure')
            } 
        } else {
            res = await GetModal('POST', 'DealerShip/LabelEdit?dealerIdSel=' + Did + '-DMSConnectivity', data)
            if(res === "Success") {
                sendResponse(0, 'Dealership label updated & re-configured successfully', 'Success')
            } else {
                sendResponse(1, 'Failed to updated & re-configure the Dealership label', 'Failure')
            }
        }       
    }

    const navigateToTemplatePage = () => {
        window.location.href = '/dashboard/templateList';
    }

    return (
        <div className="container">
            <div className="modalTop-body mt-2">
                <div className="flex justify-between items-center">
                    <p className="sampleLabel">Dealer ID</p>
                    <input className="textInput" type="number" placeholder="109" value={dealID} readOnly />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Dealership Name</p>
                    <input className="textInput" type="text" placeholder="ABC Consultency" value={dealName} readOnly onChange={(e) => {setDealName(e.target.value)}} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Contact Person</p>
                    <input className="textInput" type="text" placeholder="Jon Doe" value={contact} onChange={(e) => {setContact(e.target.value)}}/>
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Phone Number</p>
                    <input className="textInput" type="tel" placeholder="(109) 7857 446" value={phone} onChange={(e) => {setPhone(e.target.value)}} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Email</p>
                    <input className="textInput" type="email" placeholder="someone@company.com" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Street</p>
                    <input className="textInput" type="text" placeholder="102 - street" value={street} onChange={(e) => {setStreet(e.target.value)}} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">City</p>
                    <input className="textInput" type="text" placeholder="102 - street" value={city} onChange={(e) => {setCity(e.target.value)}} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">State</p>
                    <input className="textInput" type="text" placeholder="Texas" value={state} onChange={(e) => {setState(e.target.value)}} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Zip</p>
                    <input className="textInput" type="text" placeholder="123456" value={zip} onChange={(e) => {setZip(e.target.value)}} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <button className="button-6" onClick={sendData}>
                        {isEmpty ? "Create" : "Save"}
                    </button>
                    <button className="button-6" onClick={navigateToTemplatePage}>Templates</button>
                </div>
            </div>
        </div>
    )
}

export default LabelConfig