import React, { useState } from "react";
import './styles.css';
import postReportData from "../api/updateReport";
import { AlertsData } from "./dashboard";

interface Props {
    onAlertChnage: AlertsData
}

const AddScannedVehicle = ({ onAlertChnage }: Props) => {
    const [vinNumber, setVinNumber] = useState<string>('');
    let Did = sessionStorage.getItem('dealerId');

    const setScannedValue = async () => {
        let dataToSend = {
            vin: vinNumber,
            id: Did
        }

        if (Did) {
            let res = await postReportData('setScanValue', dataToSend);
            if (res.scanned === true) {
                sendResponse(0, 'Vehicle with vin number' + vinNumber + 'has been scanned successfully', 'Success')
            } else {
                sendResponse(1, 'Error occured while scanning the vehicle with vin number' + vinNumber + '', 'Failure')
            }
        }
    }

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    return (
        <div>
            <div className="flex items-center justify-between">
                <p className="sampleLabel text-graphite">Please Enter the VIN Number</p>
                <input type="text" className="textInput text-graphite" placeholder="VIN Number of the vehicle" value={vinNumber} onChange={(e) => { setVinNumber(e.target.value) }} />
            </div>
            <div className="flex items-center justify-between mt-3">
                <button className="button-6" onClick={setScannedValue}>OK</button>
                {/* <button className="button-6">Cancel</button> */}
            </div>
        </div>
    )
}

export default AddScannedVehicle