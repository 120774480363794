import React, { useState, useEffect, useCallback, useRef } from 'react';
import GetModal from '../api/getModal';
import './styles.css';

interface ReportData {
    [key: string]: string
}

interface ContentRef {
    current: HTMLDivElement | null;
}

const ReportSummary = () => {
    const [reportData, setReportData] = useState<ReportData>({});
    let Did = sessionStorage.getItem('dealerId')
    let printContent = useRef<HTMLDivElement>(null);;

    const fetchData = useCallback(async () => {
        if (Did) {
            let res = await GetModal('GET', 'DealerShip/DMSCreate?dealerIdSel=' + parseInt(Did) + '-ReportSummary', '')
            setReportData(res)
        }
    }, [Did])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const printReport = () => {
        const content = printContent.current;
        if (content) {
            const printWindow = window.open('', '', 'height=400,width=800');

            printWindow?.document.write(content.innerHTML);
            printWindow?.document.close();
            printWindow?.focus();
            printWindow?.print();
            printWindow?.close();
        }
    }
    return (
        <div className="mt-4" id="printEl">
            <div className="grid" ref={printContent}>
                {Object.entries(reportData).map(([key, value]) => {
                    const formattedKey = key.replace(/([A-Z])/g, ' $1');

                    return (
                        <div key={key} className="grid grid-cols-3 items-center border border-coal text-sm py-2">
                            <p className="col-span-2 capitalize text-graphite border-r border-coal pl-2 sampleLabel">{formattedKey}</p>
                            {/* <p className="px-1 text-graphite border-r border-coal text-center sampleLabel">:</p> */}
                            <p className="text-graphite text-center sampleLabel">{value}</p>
                        </div>
                    );
                })}
            </div>
            <div className="mt-4 flex items-center justify-center">
                <button className="button-6" onClick={printReport}>Print Report</button>
            </div>
        </div>
    )
}

export default ReportSummary