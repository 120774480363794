import { makeAPIRequest } from "./makeRequest";

function getCookie(name:any) {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();

        if (cookie.startsWith(name + '=')) {
            return cookie.split('=')[1];
        }
    }
}

const PasswordReset = (opType: string, data: string) => {
    let uname = getCookie('userName')
    let config;
    if(opType === "check") {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/CheckOldPassword?password=' + encodeURIComponent(data) + '&uname=' + uname,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (opType === 'update') {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/ChangePassword?password=' + encodeURIComponent(data) + '&uname=' + uname,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export default PasswordReset