import { makeAPIRequest } from "./makeRequest";
let config;

const getUrlAndHeaders = (type: string) => {
    if (type === 'URL') {
        return process.env.REACT_APP_SERVER_URL
    } else {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

export const CheckActiveUsers = (userId: string) => {
    config = {
        method: 'GET',
        url: getUrlAndHeaders('URL') + 'VehWizAPI/ActiveUsers?dealerid=' + userId,
        headers: getUrlAndHeaders('headers')
    }

    let res = makeAPIRequest(config);
    return res
}

export const ChangeActiveStatusOfUser = (userId: string) => {
    config = {
        method: 'GET',
        url: getUrlAndHeaders('URL') + 'VehWizAPI/ChangeActiveStates?dealerid=' + userId,
        headers: getUrlAndHeaders('headers')
    }

    let res = makeAPIRequest(config);
    return res
}

export const CheckCredentials = (userName: string, passwd: string, userId: string) => {
    config = {
        method: 'GET',
        url: getUrlAndHeaders('URL') + 'VehWizAPI/CheckGivenCredentials?uname=' + userName + '&dealerid=' + userId + '&password=' + encodeURIComponent(passwd),
        headers: getUrlAndHeaders('headers')
    }

    let res = makeAPIRequest(config);
    return res
}