import { makeAPIRequest } from "./makeRequest";

export const CSVFileGetter = () => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'FileBasedDMS/Index',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const DeleteCSVFiles = (fileName: string, filePath: string) => {
    let config = {
        method: 'DELETE',
        url: process.env.REACT_APP_SERVER_URL + 'FileBasedDMS/DeleteFile?fileName=' + fileName + '&filePath=' + filePath,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const UploadCSVFiles = (file: File) => {
    let config = {
        method: 'POST',
        url: process.env.REACT_APP_SERVER_URL + 'FileBasedDMS/UploadFileBasedCSV',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        },
        data: {
            file: file
        }
    }

    let res = makeAPIRequest(config);
    return res;
}