import React, { useState } from "react";

interface PopoverProps {
    isOpen: boolean;
    label: string;
}

const PopOver: React.FC<PopoverProps> = ({ isOpen, label }) => {
    return (
        <div className="relative">
            {isOpen && (
                <div className="absolute z-10 p-3 text-sm text-gray-800 bg-white border rounded shadow -top-5 -left-3">
                    {label}
                </div>
            )}
        </div>
    );
}

export default PopOver