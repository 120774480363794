import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoutes = () => {
    function getCookie(name:any) {
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();

            if (cookie.startsWith(name + '=')) {
                return cookie.split('=')[1];
            }
        }
    }

    let token = getCookie('accessToken')
    return (
        token ? <Outlet /> : <Navigate to="/unauthorised" />
    )
}

export default ProtectedRoutes