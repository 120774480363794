import { makeAPIRequest } from "./makeRequest";

const SaveGridData = (option: number, gridData: any) => {
    let config
    if (option === 1) {
        config = {
            method: 'POST',
            url: process.env.REACT_APP_SERVER_URL + 'GridSettingsForReact/GridSettingsDealership',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: { 'columnOpts': JSON.stringify(gridData) }
        }
    } else if(option === 2) {
        config = {
            method: 'POST',
            url: process.env.REACT_APP_SERVER_URL + 'GridSettingsForReact/GridSettingsUsers',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: { 'columnOpts': JSON.stringify(gridData) }
        }
    } else if(option === 3) {
        config = {
            method: 'DELETE',
            url: process.env.REACT_APP_SERVER_URL + 'GridSettingsForReact/ClearGridSettingsDealership',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else {
        config = {
            method: 'DELETE',
            url: process.env.REACT_APP_SERVER_URL + 'GridSettingsForReact/ClearGridSettingsUser',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    let res = makeAPIRequest(config)
    return res
}

export default SaveGridData