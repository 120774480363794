import React, { useState, useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import './styles.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { ScheduleReportsAPI, ClearAllSchedulesAPI } from "../api/scheduleReportsApi";
import Alert from "../components/alert";

interface ApiData {
    time: string;
    weekday: string;
    selectedReport: string;
}

interface SelectedData {
    day: string;
    report: string;
    selected: boolean;
    time?: Date;
}

interface submitData {
    schReportID: number,
    time: string,
    weekday: string,
    selectedReport: string,
    enable: boolean
}

interface ErrorState {
    code: number;
    message: string;
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const reports = ['AllVehicles', 'MissingVehicles', 'ScannedVehicles', 'ExtraVehicles'];

const ScheduleReports = () => {
    const [apiData, setApiData] = useState<ApiData[]>([]);
    const [selected, setSelected] = useState<SelectedData[]>([]);
    const [checkBox, setCheckBox] = useState<boolean>(false);
    const [error, setError] = useState<ErrorState>();
    useEffect(() => {
        async function fetchData() {
            const res = ScheduleReportsAPI('GET', '');
            const data = await res;
            setApiData(data);
        }

        fetchData();
    }, []);

    useEffect(() => {
        const mappedData = apiData.map(d => ({
            day: d.weekday,
            report: d.selectedReport,
            selected: true,
            // time: d.time ? new Date(d.time?.split('T')) : undefined
            time: new Date(new Date(d.time + 'Z').toLocaleString())
        }));

        setSelected(mappedData);
    }, [apiData]);

    const handleCheckboxChange = (day: string, report: string) => {
        const currentlySelected = selected.find(
            s => s.day === day && s.report === report
        );

        if (currentlySelected) {
            setSelected(prev =>
                prev.filter(s => !(s.day === day && s.report === report))
            );
        } else {
            setSelected(prev => [
                ...prev,
                {
                    day,
                    report,
                    selected: true,
                    time: new Date()
                }
            ]);
        }
    };

    const handleTimeChange = (day: string, report: string, time: Date) => {
        setSelected(prev =>
            prev.map(s => {
                if (s.day === day && s.report === report) {
                    return { ...s, time };
                }
                return s;
            })
        );
    };

    const handleSubmitRecords = async () => {
        const dataToSave = selected.map(s => ({
            weekday: s.day,
            selectedReport: s.report,
            time: s.time?.toISOString()
        }));

        if (dataToSave.length === 0) {
            const clearRecordsResult = await ClearAllSchedulesAPI()
            if (clearRecordsResult.result === "Succcess") {
                setError({ code: 0, message: 'Schedules cleared successfully' });
            } else {
                setError({ code: 1, message: 'Failed to clear the schedule' });
            }
        } else {
            const res = await ScheduleReportsAPI('PUT', dataToSave)
            if (res.result === "Success") {
                setError({ code: 0, message: 'Reports scheduled successfully' });
            } else {
                setError({ code: 1, message: 'Failed to schedule the reports' });
            }
        }
    }

    return (
        <div>
            <div className="text-center mt-3">
                <p className="headerText font-bold text-2xl text-graphite">Please select reports and time</p>
            </div>
            <div className="mt-3">
                <table className="flex justify-center">
                    <tbody className='border p-2 headerText font-thin text-left antialiased text-base text-coal'>
                        {days.map(day => (
                            <tr key={day}>
                                <td className="border border-coal pl-5 pr-5">{day}</td>

                                {reports.map(report => (
                                    <td key={report} className="border border-coal pl-5 pr-5">
                                        <input type="checkbox"
                                            checked={
                                                selected.some(s => s.day === day && s.report === report && s.time)
                                            }
                                            onChange={() => {
                                                handleCheckboxChange(day, report)
                                            }}
                                        />
                                        <label className="sampleLabel pl-2 pr-3">{report}</label>
                                        <DateTimePicker
                                            value={selected.find(s => s.day === day && s.report === report)?.time}
                                            onChange={time => handleTimeChange(day, report, time ? time : new Date())}
                                            disableCalendar
                                            format="HH:mm:ss"
                                            className="sampleLabel text-graphite"
                                        />
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 flex items-center justify-center">
                <button className="button-6" onClick={handleSubmitRecords}>Save Records</button>
            </div>
            {error ? <Alert code={error.code} message={error.message} /> : null}
        </div>
    );
}

export default ScheduleReports;