import React, { useState } from "react";
import { HideData } from "./dashboard";
import { GridDataState } from "../utils/store";
import { useSelector } from "react-redux";
import './styles.css'

interface Props {
    onHideDataChange: HideData
}

const ColumnChooser = ({ onHideDataChange }: Props) => {
    const initialGridData = useSelector((state: GridDataState) => state.gridData.gridData);
    const [gridData, setGridData] = useState(initialGridData);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const isChecked = event.target.checked;
        const updatedGridData = gridData.map((item: { key: any; }) => {
            if (item.key === key) {
                return { ...item, hidden: !isChecked };
            }
            return item;
        });
        setGridData(updatedGridData);

        let data = {
            'key': key,
            'hidden': !isChecked
        };

        onHideDataChange(data);
    };


    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg">
            <div className="px-6 py-4">
                {gridData.map((item: { key: string , hidden: boolean }, index: React.ChangeEvent<HTMLInputElement> | React.Key | null | undefined) => (
                    <div className="flex items-center capitalize">
                        <input
                            type="checkbox"
                            id={`printButton-${index}`}
                            checked={!item.hidden}
                            onChange={(e) => handleChange(e, item.key)}
                        />&nbsp;&nbsp;
                        <label htmlFor={`printButton-${index}`} className="sampleLabel text-graphite">{item.key}</label>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ColumnChooser