import { makeAPIRequest } from "./makeRequest";

export const getVehicleReports = (reportType: string, id: number, initalData: boolean) => {
    let config;
    if(initalData) {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'Vehicle/GetUniqueStatusesAndLots?dealid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'Vehicle/GetVehicles?dealid=' + id + '&report='+ reportType,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    try {
        let res = makeAPIRequest(config);
        return res
    } catch (err) {
        console.log(err)
    }
}