import React, { useState, useEffect, useCallback } from 'react'
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import './styles.css'
import GetModal from '../api/getModal';
import { GridDataState } from '../utils/store';
import { useSelector } from 'react-redux';
import { AlertsData } from './dashboard';

interface Props {
    onAlertChnage: AlertsData
}

const AddVehicles = ({onAlertChnage}: Props) => {
    const gridData = useSelector((state: GridDataState) => state.gridData);
    let vin = gridData.gridData.vin;
    let stock = gridData.gridData.stockNo;
    let color = gridData.gridData.color;
    let scannedLotLocation = gridData.gridData.scannedLotLocation;

    const [vinNumber, setVinNumber] = useState<string>();
    const [stockNumber, setStockNumber] = useState<string>('');
    const [colorData, setColorData] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [make, setMake] = useState<string>('');
    const [model, setModel] = useState<string>('');
    const [trim, setTrim] = useState<string>('');
    const [fuelType, setFuelType] = useState<string>('');
    const [listPrice, setListPrice] = useState<string>('');
    const [vehCost, setVehCost] = useState<string>('');
    const [lot, setLot] = useState<string>('');
    const [truck, setTruck] = useState<string>('');
    const [grossWt, setGrossWt] = useState<string>('');
    const [odo, setOdo] = useState<string>('');
    const [inspMonth, setInspMonth] = useState<string>('');
    const [engine, setEngine] = useState<string>('');
    const [bodyStyle, setBodyStyle] = useState<string>('');
    const [trans, setTrans] = useState<string>('');
    const [colorCode, setColorCode] = useState<string>('');
    const [engineCode, setEngineCode] = useState<string>('');
    const [trunkKeyCode, setTrunkKeyCode] = useState<string>('');
    const [keylessCode, setKeylessCode] = useState<string>('');
    const [radioCode, setRadioCode] = useState<string>('');
    const [wheellockCode, setWheellockCode] = useState<string>('');
    const [dealerCode, setDealerCode] = useState<string>('');
    const [inventoryDate, setInventoryDate] = useState<Date>();
    const [serviceDate, setServiceDate] = useState<Date>();
    const [salesAccount, setSalesAccount] = useState<string>('');
    const [inventoryAccount, setInventoryAccount] = useState<string>('');
    const [warranty, setWarranty] = useState<number>(0);
    const [miles, setMiles] = useState<number>(0);
    const [deductible, setDeductible] = useState<number>(0);
    const [demoName, setDemoName] = useState<string>('');
    const [companyID, setCompanyID] = useState<string>('');
    const [vehType, setVehType] = useState<string>('');
    const [companyIdList, setcompanyIdList] = useState<string[]>([]);
    const [engineList, setEngineList] = useState<number[]>([]);
    const [transmissionList, setTransmissionList] = useState<string[]>([]);
    const [fuelList, setFuelList] = useState<string[]>([]);
    const [bodyList, setBodyList] = useState<string[]>([]);
    let Did = sessionStorage.getItem('dealerId')

    const fetchData = useCallback(async () => {
        if (vin || stock || color) {
            setVinNumber(vin);
            setColorData(color);
            let data = await GetModal('GET', 'DealerShip/DMSCreate?dealerIdSel=' + vin + ';' + scannedLotLocation + ';' + color + ';' + stock + '-ListOfVehiclesdata', '');
            setVinNumber(data.vin);
            setStockNumber(data.stockNo);
            setColorData(data.color);
            setYear(data.modelyear);
            setMake(data.make);
            setModel(data.model);
            setTrim(data.trim);
            setFuelType(data.fuelTType);
            setListPrice(data.listPrice);
            setVehCost(data.vehicleCost);
            setLot(data.lot);
            setTruck(data.truck);
            setGrossWt(data.grossWeight);
            setOdo(data.odometer);
            setInspMonth(data.inspectionMonth);
            setEngine(data.engine);
            setBodyStyle(data.style);
            setTrans(data.transmission);
            setColorCode(data.colorCode);
            setEngineCode(data.engineCode);
            setTrunkKeyCode(data.trunkKeyCode);
            setKeylessCode(data.keylessCode);
            setRadioCode(data.radioCode);
            setWheellockCode(data.wheelLockCode);
            setDealerCode(data.dealerCode);
            setInventoryDate(new Date(data.dateInInventory));
            setServiceDate(new Date(data.dateInService));
            setSalesAccount(data.saleAccount);
            setInventoryAccount(data.inventoryAccount);
            setWarranty(data.warrantyMonths || 0);
            setMiles(data.warrantyMiles || 0);
            setDeductible(data.warrantyDeduct || 0);
            setDemoName(data.demoName);
            setCompanyID(data.companyid);
            setTransmissionList(data.listOfTransmissions);
            setEngineList(data.listOfEngines);
            setFuelList(data.listOfFuelTypes);
            setBodyList(data.listOfBodyStyles);
            setcompanyIdList(data.listOfCompanyIds);
        }
    }, [vin, stock, color])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const sendData = async () => {
        let dataToSend = {
            "DealerId": Did,
            "Vin": vinNumber,
            "Type": vehType,
            "Make": make,
            "Model": model,
            "Year": year,
            "Description": '',
            "StockNo": stockNumber,
            "Companyid": companyID,
            "SalesGroupTxT": null,
            "SalesGroup": null,
            "Style": bodyStyle,
            "Trim": trim,
            "Engine": engine,
            "FuelType": fuelType,
            "FuelTType": '',
            "Transmission": trans,
            "Color": colorData,
            "Lot": lot,
            "Mpg": null,
            "GLApplied": null,
            "VehicleCost": vehCost,
            "ListPrice": listPrice,
            "FourWheelDrive": null,
            "Turbo": null,
            "GrossWeight": grossWt,
            "CertifiedUsedCar": null,
            "Odometer": odo,
            "OdometerActual": null,
            "InspectionMonth": inspMonth,
            "ColorCode": colorCode,
            "EngineCode": engineCode,
            "IgnitionKeyCode": null,
            "TrunkKeyCode": trunkKeyCode,
            "KeylessCode": keylessCode,
            "RadioCode": radioCode,
            "WheelLockCode": wheellockCode,
            "DealerCode": dealerCode,
            "DateInInventory": inventoryDate?.toISOString(),
            "DateInService": serviceDate?.toISOString(),
            "SaleAccount": salesAccount,
            "InventoryAccount": inventoryAccount,
            "WarrantyMonths": warranty.toString(),
            "WarrantyMiles": miles.toString(),
            "WarrantyDeduct": deductible.toString(),
            "DemoName": demoName,
            "LicenseNumber": null,
            "WorkInProcess": null,
            "OptionCode": null,
            "Truck": truck,
            "OptionNumber": null,
            "FieldType": null,
            "AlphaFieldValue": null,
            "NumericFieldValue": null,
            "DateFieldValue": new Date().toISOString(),
            "AddToCostFlag": null
        }
        try {
            let res = await GetModal('POST', 'DealerShip/ChromeVehicleDataFromReact', { 'chromeData': dataToSend })
            if(res) {
                sendResponse(0, 'Vehicle data added successfully', 'Success')
            }
        } catch (err: any) {
            console.log(err)
            sendResponse(1, err.message, 'Failure')
        }
    }

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    return (
        <div className='h-[80vh] overflow-y-scroll p-4'>
            <div className="modalTop-body">
                <p className="headerText text-xl text-center">Chrome data vehicle details</p>
                <p className="mt-2 headerText text-sm font-bold">Please fill out the following required fields</p>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Stock number</p>
                    <input className="textInput" type="text" required value={stockNumber} onChange={(e) => { setStockNumber(e.target.value) }} />
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Type</p>
                    <select className="textInput" required value={vehType} onChange={(e) => { setVehType(e.target.value) }} >
                        <option selected>Select</option>
                        <option value="New">New</option>
                        <option value="Used">Used</option>
                    </select>
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="sampleLabel">Company ID</p>
                    <select className="textInput" value={companyID} onChange={(e) => setCompanyID(e.target.value)}>
                        {companyIdList.map(companyData => (
                            <option key={companyData} value={companyData}>{companyData}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="modal-body">
                <p className="mt-3 headerText mb-3 text-sm font-bold">Non Required Fields</p>
                {/* Accordians */}
                <Accordion>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Derived From VIN
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">VIN</p>
                                <input className="textInput" type="text" value={vinNumber} onChange={(e) => { setVinNumber(e.target.value) }} placeholder='Enter the Vin Number of the vehicle' />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Year</p>
                                <input className="textInput" type="number" value={year} onChange={(e) => setYear(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Make</p>
                                <input className="textInput" type="text" value={make} onChange={(e) => setMake(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Model</p>
                                <input className="textInput" type="text" value={model} onChange={(e) => { setModel(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Trim</p>
                                <input className="textInput" type="text" value={trim} onChange={(e) => { setTrim(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Fuel Type</p>
                                <select value={fuelType} onChange={(e) => { setFuelType(e.target.value) }} className="textInput">
                                    {fuelList.map(fuelData => (
                                        <option key={fuelData} value={fuelData}>{fuelData}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">List Price</p>
                                <input className="textInput" type="number" value={listPrice} onChange={(e) => setListPrice(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Vehicle Cost</p>
                                <input className="textInput" type="number" value={vehCost} onChange={(e) => setVehCost(e.target.value)} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Vehicle Details
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Color</p>
                                <input className="textInput" type="text" value={colorData} onChange={(e) => { setColorData(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Lot</p>
                                <input className="textInput" type="text" value={lot} onChange={(e) => { setLot(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Truck</p>
                                <input className="textInput" type="text" value={truck} onChange={(e) => { setTruck(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Gross weight</p>
                                <input className="textInput" type="text" value={grossWt} onChange={(e) => setGrossWt(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Odometer</p>
                                <input className="textInput" type="text" value={odo} onChange={(e) => { setOdo(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Inspection Month</p>
                                <input className="textInput" type="text" value={inspMonth} onChange={(e) => setInspMonth(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Engine</p>
                                <select className="textInput" value={engine} onChange={(e) => setEngine(e.target.value)}>
                                    {engineList.map(engineData => (
                                        <option key={engineData} value={engineData}>{engineData}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Body Style</p>
                                <select className="textInput" value={bodyStyle} onChange={(e) => setBodyStyle(e.target.value)}>
                                    {bodyList.map(bodyData => (
                                        <option key={bodyData} value={bodyData}>{bodyData}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Transmission Code</p>
                                <select className="textInput" value={trans} onChange={(e) => setTrans(e.target.value)}>
                                    {transmissionList.map(trans => (
                                        <option key={trans} value={trans}>{trans}</option>
                                    ))}
                                </select>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Codes
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Color Code</p>
                                <input className="textInput" type="text" value={colorCode} onChange={(e) => setColorCode(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Engine Code</p>
                                <input className="textInput" type="text" value={engineCode} onChange={(e) => { setEngineCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Trunk key code</p>
                                <input className="textInput" type="text" value={trunkKeyCode} onChange={(e) => { setTrunkKeyCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Keyless code</p>
                                <input className="textInput" type="text" value={keylessCode} onChange={(e) => { setKeylessCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Radio code</p>
                                <input className="textInput" type="text" value={radioCode} onChange={(e) => { setRadioCode(e.target.value) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Wheel lock code</p>
                                <input className="textInput" type="text" value={wheellockCode} onChange={(e) => setWheellockCode(e.target.value)} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Dealer code</p>
                                <input className="textInput" type="text" value={dealerCode} onChange={(e) => setDealerCode(e.target.value)} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Dates
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Date in inventory</p>
                                <input className="textInput" type="datetime-local" value={inventoryDate ? inventoryDate.toISOString().slice(0, -8) : ''} onChange={(e) => setInventoryDate(new Date(e.target.value))} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Date in service</p>
                                <input className="textInput" type="datetime-local" value={serviceDate ? serviceDate.toISOString().slice(0, -8) : ''} onChange={(e) => setServiceDate(new Date(e.target.value))} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Accounts
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Sale Account</p>
                                <select className="textInput" value={salesAccount} onChange={(e) => { setSalesAccount(e.target.value) }}>
                                    <option selected>Select sale account</option>
                                    <option>6</option>
                                </select>
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Inventory Account</p>
                                <select className="textInput" value={inventoryAccount} onChange={(e) => { setInventoryAccount(e.target.value) }}>
                                    <option selected>Select inventory account</option>
                                    <option>6</option>
                                </select>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Warranty
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Months</p>
                                <input className="textInput" type="number" value={warranty} onChange={(e) => { setWarranty(parseInt(e.target.value)) }} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Miles</p>
                                <input className="textInput" type="number" value={miles} onChange={(e) => setMiles(parseInt(e.target.value))} />
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Deductible</p>
                                <input className="textInput" type="number" value={deductible} onChange={(e) => setDeductible(parseInt(e.target.value))} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <hr />
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Dealership Internal
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="flex justify-between items-center mt-2">
                                <p className="sampleLabel">Demo Name</p>
                                <input className="textInput" type="text" value={demoName} onChange={(e) => { setDemoName(e.target.value) }} />
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="modal-footer">
                <div className="flex justify-around items-center mt-4">
                    <button className="button-6" onClick={sendData}>
                        Send
                    </button>
                    {/* <button className="button-6">Cancel</button> */}
                    <button className="button-6">Print</button>
                </div>
            </div>
        </div>
    )
}

export default AddVehicles