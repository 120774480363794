import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faGear, faPlus, faFileLines, faQuestion, faCalendarCheck, faComputer, faChevronRight, faChevronLeft, faTowerBroadcast, faCartShopping, faScrewdriverWrench, faGears } from "@fortawesome/free-solid-svg-icons";
import { SidebarToggle, SidebarMenuSelected } from "../pages/dashboard";
import sideBarLogo from '../assets/sideBarLogo.png'

interface Props {
    onToggle: SidebarToggle;
    onMenuSelected: SidebarMenuSelected;
}

interface MenuItem {
    label: string;
    icon: any;
    subMenu?: string[];
}

interface MenuState {
    [key: string]: boolean;
}

const menuItems: MenuItem[] = [
    {
        label: "Reports", icon: faFileLines, subMenu: [
            'All Vehicles',
            'Report Missing',
            'Report Scanned',
            'Report-Extra Vehicles',
            'Report Summary',
            'Lot History',
            'Scanned Vehicle Notes',
            'User Devices'
        ]
    },
    {
        label: "Tools", icon: faScrewdriverWrench, subMenu: [
            'Print Labels',
            'Print Report',
            'Manage Vehicle Status',
            'Export Data',
            'Vehicle Accessory'
        ]
    },
    {
        label: "Shop", icon: faCartShopping, subMenu: [
            "Order Labels"
        ]
    },
    {
        label: "Settings", icon: faGears, subMenu: [
            'DMS Connectivity',
            'Label Config',
            'Preferences',
            'Download Label Print Support',
            'User List',
            'Enable QR-Code',
            'Scheduled Reports',
            'CSV Files For FileBased',
            'Save grid settings',
            'Clear grid settings',
            'Column Selector'
        ]
    },
    {
        label: "Help", icon: faQuestion, subMenu: [
            'Request Remote Support',
            'User Instructions'
        ]
    },
    // {
    //     label: "File", icon: faFile, subMenu: [
    //         'Add Scanned Vehicles',
    //     ]
    // },
    // {
    //     label: "Beacons List", icon: faTowerBroadcast, subMenu: [
    //         "Beacon - Keys",
    //         "Beacon - Vehicles",
    //         "Report - For Vehicles",
    //         "Report - For Keys"
    //     ]
    // },
]

const SideBar: React.FC<Props> = ({ onToggle, onMenuSelected }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [openMenus, setOpenMenus] = useState<MenuState>({});

    const toggle = () => {
        setCollapsed(!collapsed)
        onToggle(!collapsed)
    }

    const menuSelected = (menu: string) => {
        if(menu === "Scheduled Reports") {
            window.location.href = '/dashboard/scheduleReport';
        } else {
            onMenuSelected(menu)
        }
    }

    return (
        <div className="w-full h-fit cursor-pointer overflow-y-auto">
            {menuItems.map((item) => (
                <div key={item.label}>
                    <div
                        className={
                            !collapsed
                                ? "flex gap-3 justify-start items-center p-2 menus cursor-pointer" +
                                (item.subMenu ? "justify-start" : "") +
                                (openMenus[item.label] ? " active-menu" : "")
                                : "flex gap-3 justify-start items-center p-2 menus cursor-pointer" +
                                (item.subMenu ? "justify-between" : "") +
                                (openMenus[item.label] ? " active-menu" : "")}
                        onClick={() => {
                            if (!item.subMenu) {
                                menuSelected(item.label);
                                setOpenMenus({});
                            } else {
                                const updated: MenuState = {};
                                if (openMenus[item.label]) {
                                    // If the menu is already open, close it
                                    setOpenMenus({});
                                } else {
                                    Object.keys(openMenus).forEach((key) => {
                                        updated[key] = false;
                                    });
                                    updated[item.label] = true;
                                    setOpenMenus(updated);
                                }
                            }
                        }}
                    >
                        {item.subMenu ? (
                            <>
                                <FontAwesomeIcon icon={item.icon} />
                                {collapsed ? null : <span>{item.label}</span>}
                                {item.subMenu && (
                                    <div className="place-content-end transition duration-200">
                                        <FontAwesomeIcon icon={faChevronRight} className={`${openMenus[item.label] ? "rotate-90" : ""} transition duration-200`} />
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={item.icon} />
                                {collapsed ? null : <span>{item.label}</span>}
                            </>
                        )}

                    </div>
                    {item.subMenu && openMenus[item.label] && (
                        <div className="sidebarOpen">
                            {item.subMenu.map(sub => (
                                <div key={sub} className="menus cursor-pointer p-1" onClick={() => {
                                    menuSelected(sub)
                                }}>
                                    <label className="text-xs smallText">{sub}</label>
                                    <hr />
                                </div>
                            ))}
                        </div>
                    )}
                    <hr />
                </div>
            ))
            }
            <div className="w-100 bottomImageStyles">
                <img src={sideBarLogo} alt="sidebar Branding" className={collapsed ? 'bottomImageStylesCollapsed' : 'bottomButtonStyleUnCollapsed'} />
            </div>
            <div className="w-100">
                <button onClick={toggle} className="bottomButtonStyle justify-between items-center pt-3 pb-3 pl-3 menus">
                    {collapsed ? <FontAwesomeIcon icon={faChevronRight} size="lg" /> : <FontAwesomeIcon icon={faChevronLeft} size="lg" />}
                </button>
            </div>
        </div >
    )
}

export default SideBar
