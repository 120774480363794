import { makeAPIRequest } from "./makeRequest";

export const ScheduleReportsAPI = (op: string, reportData: any) => {
    let config: object
    if (op === "GET") {
        config = {
            method: 'get',
            url: process.env.REACT_APP_SERVER_URL + 'ScheduledReports/Index',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else {
        config = {
            method: 'PUT',
            url: process.env.REACT_APP_SERVER_URL +'ScheduledReports/Edit',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            data: { 'scheduledReports': reportData }
        }
    }
    let res = makeAPIRequest(config)
    return res
}

export const ClearAllSchedulesAPI = () => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'ScheduledReports/ClearForUser',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config)
    return res
}