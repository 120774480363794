import React from "react";
import './styles.css';

const HelpPage = () => {
    return (
        <div className="flex flex-col justify-center items-center mx-auto">
            <div className="glass1 p-5">
                <h2 className="headerText text-center text-3xl pb-3">Welcome to Vehicle wizard web</h2>
                <div className="flex pb-3">
                    <p className="text-lg pr-4">Want to watch the demo Video ?</p>
                    <a href="/dashboard/demoVideo" className="text-lg">Click here</a>
                </div>
                <div>
                    <p className="headerText text-lg">If you have any questions about these Terms and Conditions, You can contact us:</p>
                    <ul className="list-disc">
                        <li className="headerText text-lg ml-6 text-coal">By email: support@scansolutions.com</li>
                        <li className="headerText text-lg ml-6 text-coal">visiting this page on our website: www.scansolutions.com/contactus</li>
                        <li className="headerText text-lg ml-6 text-coal">By phone number: 866.834.1194</li>
                        <li className="headerText text-lg ml-6 text-coal">By mail: 9700 Farrar Ct Richmond, VA 23236, United States</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default HelpPage