import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faRefresh, faClipboardList, faUpload, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Outlet } from "react-router-dom";
import Logo from "../assets/Logo1.png"
import useToken from "../hooks/useToken";
import PopOver from "../components/popOver";
import { ReportTypeProvider } from "../utils/reportTypeContext";
import './layoutStyles.css';
import GetHeaderType from "../api/getHeaderType";
import { CDKDownload, ManualRefreshOptions } from "../api/manualRefresh";

const MainLayout = () => {
    const { logOut } = useToken();
    const [isOpen, setIsOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [popoverLabel, setPopoverLabel] = useState<string>('');
    const [reportType, setReportType] = useState<string>('');
    const [buttons, setButtons] = useState([
        { label: 'Print Report', icon: faPrint },
        { label: 'Begin New Inventory', icon: faPlus },
        { label: 'Refresh', icon: faRefresh },
        { label: 'Upload DMS', icon: faUpload },
    ]);
    const [hoveredButtonIndex, setHoveredButtonIndex] = useState<number | null>(null);
    const [polling, setPolling] = useState<boolean>(false);
    const [showLoadingModal, setShowLoadingModal] = useState<boolean>(false);
    let username = sessionStorage.getItem("userName");
    let dealerCode = sessionStorage.getItem("dealerId");
    let dealerCodeData = sessionStorage.getItem("dealercode");
    let userEmail = sessionStorage.getItem('email');

    const handelLogout = () => {
        window.history.replaceState(null, '', '/');
        window.location.href = '/';
        logOut();
        const dealerId = sessionStorage.getItem("dealerId");
        const userName = sessionStorage.getItem("userName");

        if (dealerId) {
            sessionStorage.removeItem("dealerId");
        }

        if (userName) {
            sessionStorage.removeItem("userName");
        }
    }

    const newInventoryEvent = new CustomEvent("newInventory", {
        detail: { eventType: "newInventory" },
    });

    const uploadDMSEvent = new CustomEvent("uploadDMS", {
        detail: { eventType: "uploadDMS" }
    })

    const handelPerformAction = async (action: string) => {
        if (action === "Refresh") {
            setShowLoadingModal(true);
            if (dealerCode) {
                let res = await ManualRefreshOptions(parseInt(dealerCode));
                if (res === 'refresh') {
                    setShowLoadingModal(false);
                    window.location.reload();
                } else {
                    if (res.value.endsWith("CDK")) {
                        setPolling(true);
                        callCDKAPI(parseInt(dealerCode));
                    } else if (res.value === "refresh") {
                        window.location.reload();
                        setShowLoadingModal(false);
                    } else {
                        alert(res.value);
                        setShowLoadingModal(false);
                        window.location.reload();
                    }
                }
            }
        } else if (action === "Begin New Inventory") {
            window.dispatchEvent(newInventoryEvent)
        } else if (action === "End Inventory") {
            if (dealerCode) {
                let result = await GetHeaderType('', parseInt(dealerCode), 'endInventory')
                if (result === "\"Success\"") {
                    window.location.reload()
                }
            }
        } else if (action === "Upload DMS") {
            window.dispatchEvent(uploadDMSEvent)
        } else if (action === "Print Report") {
            setReportType("printReport");
        }
    }

    const callCDKAPI = useCallback(async (userId: number) => {
        if (!polling) return

        try {
            let res = await CDKDownload(userId)
            if (res.message !== "Requested") {
                setPolling(false);
                setShowLoadingModal(false);
                console.log(res)
                window.location.reload()
            } else {
                setTimeout(() => callCDKAPI(userId), 15000)
            }
        } catch (err) {
            console.log(err)
            setPolling(false);
        }

    }, [polling])

    const fetchData = useCallback(async () => {
        if (dealerCode) {
            let headerRes = await GetHeaderType('', parseInt(dealerCode), 'initial');
            setButtons((prevButtons) =>
                prevButtons.map((button) =>
                    button.label === 'Begin New Inventory'
                        ? {
                            ...button,
                            label:
                                headerRes === '"Begin New Inventory"'
                                    ? 'Begin New Inventory'
                                    : headerRes === '"End Inventory"'
                                        ? 'End Inventory'
                                        : button.label,
                            icon:
                                headerRes === '"Begin New Inventory"'
                                    ? faPlus
                                    : headerRes === '"End Inventory"'
                                        ? faMinus
                                        : button.icon,
                        }
                        : button
                )
            );
        }
    }, [dealerCode])

    useEffect(() => {
        if (polling && dealerCode) {
            callCDKAPI(parseInt(dealerCode))
        }

        fetchData()
    }, [fetchData, callCDKAPI])

    return (
        <ReportTypeProvider reportType={reportType}>
            <div className="h-screen">
                <header className="pr-10 pl-10 headerImageContainer">
                    <div className="flex justify-between items-center">
                        <img src={Logo} alt="Vww_Logo" className="w-100 h-16 cursor-pointer" />
                        <div className="flex justify-between items-center w-100">
                            <div className="pr-4">
                                {buttons.map((button, index) => (
                                    <div key={index} className="relative inline-block">
                                        <button
                                            className="p-4"
                                            onMouseEnter={() => {
                                                setPopoverOpen(true);
                                                setPopoverLabel(button.label);
                                                setHoveredButtonIndex(index);
                                            }}
                                            onMouseLeave={() => {
                                                setPopoverOpen(false);
                                                setPopoverLabel('');
                                                setHoveredButtonIndex(null);
                                            }}
                                            onClick={() => {
                                                handelPerformAction(button.label)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={button.icon} color="#FBFCFA" />
                                        </button>
                                        {popoverOpen && hoveredButtonIndex === index && (
                                            <div className="absolute left-1/2 transform -translate-x-1/2 mt-1 whitespace-nowrap">
                                                <PopOver isOpen={true} label={popoverLabel} />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
							<div>
                                <a href="/dashboard/help" className="block px-4 py-2 no-underline headerLabelStyle text-white">
                                    How To...
                                </a>
                            </div>
                            <div className="relative pl-4">
                                <button onClick={() => setIsOpen(!isOpen)}
                                    className="button-33">
                                    <u>Account</u>
                                </button>
                                {isOpen && (
                                    <div
                                        id="userDropdown"
                                        className="absolute right-0 mt-2 w-60 bg-white divide-y divide-gray-100 rounded-lg shadow z-10 dark:bg-gray-700 dark:divide-gray-600"
                                    >
                                        <div className="px-4 py-3 text-sm text-gray-900 dark:text-white">
                                            <div className="font-md">Hi, </div>
                                            <div className="capitalize">{username}</div>
                                            <div className="font-normal">{userEmail}</div>
                                            <div className="font-medium truncate">Dealer Id: {dealerCode}</div>
                                            <div className="font-medium truncate">Dealer Code: {dealerCodeData}</div>
                                        </div>
                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton">
                                            <li>
                                                <a href="/dashboard" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white no-underline headerLabelStyle">
                                                    Dashboard
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/dashboard/passwordChange" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white no-underline headerLabelStyle">
                                                    Change Password
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/assets/Vehicle_Wizard_Release_Notes.pdf" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white no-underline headerLabelStyle" target="_blank" rel="noopener noreferrer">
                                                    Release Notes
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/dashboard/feedback" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white no-underline headerLabelStyle">
                                                    Feedback
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a href="/dashboard/help" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white no-underline headerLabelStyle">
                                                    Help
                                                </a>
                                            </li> */}
                                        </ul>
                                        <div className="py-1">
                                            <a onClick={handelLogout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer no-underline headerLabelStyle">
                                                Log out
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </header>
                <main className="flex mx-auto flex-grow h-fit">
                    <Outlet />
                </main>
                {showLoadingModal && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-20">
                        <div className="bg-white p-5 rounded-lg shadow-xl">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900 mx-auto"></div>
                            <p className="text-center mt-4">Loading...</p>
                        </div>
                    </div>
                )}
            </div>
        </ReportTypeProvider>
    )
}

export default MainLayout