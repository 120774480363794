const DataTransformer = (type: string, initialArray: any[], keyList: string[]) => {
    let resultantArray: any[] = [];
    if (type === "vehicleBeacon" || type === 'keyBeacon'  || type === "beaconKeyDisplay")
    initialArray.forEach((items: { [x: string]: any; hasOwnProperty: (arg0: any) => any; }) => {
        let obj: { [key: string]: any } = {};
        keyList.forEach((element: any) => {
            if (items.hasOwnProperty(element)) {
                obj[element] = items[element];
            } else {
                obj[element] = null
            }
        });
        resultantArray.push(obj)
    })

    return resultantArray
}

export default DataTransformer