import React, { useState, useEffect, useCallback, useRef } from "react";
import './styles.css'
import GetModal from "../api/getModal";
import { AlertsData } from './dashboard';
import * as JSPM from "jsprintmanager";
import NotifyComponent from "../components/notifyComponent";
import { DownloadJSPMDriver } from "../utils/downloadDrivers";

interface Props {
    onAlertChnage: AlertsData
}

interface Printer {
    name: string;
}


const Preferences = ({ onAlertChnage }: Props) => {
    const [printManager, setPrintManager] = useState(0);
    const [printDriver, setPrintDriver] = useState('');
    const [supportLotLoc, setSupportLotLoc] = useState(false);
    const [supportStock, setSupportStock] = useState(false);
    const [supportColor, setSupportColor] = useState(false);
    const [supportLivePosting, setSupportLivePosting] = useState(false);
    const [defaultPrinter, setDefaultPrinter] = useState<any>('Select Printer');
    const [printId, setPrintId] = useState<number>();
    const [dealership, setDealership] = useState({});
    const [dealeshipName, setDealershipName] = useState<string>('');
    const [printers, setPrinters] = useState<Printer[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [error, setError] = useState<{ code?: number, message?: string }>({})
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fetchPrintersRef = useRef(false);
    let Did = sessionStorage.getItem('dealerId');

    const handelPrintManagerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrintManager(parseInt(e.target.value));
    }

    const handelPrintDriverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrintDriver(e.target.value);
    }

    const sendResponse = (errCode: number, errMsg: string, status: string) => {
        onAlertChnage(errCode, errMsg, status)
    }

    const fetchData = useCallback(async () => {
        let res = await GetModal('GET', 'DealerShip/DMSCreate?dealerIdSel=' + Did + '-Preferences', '')
        setDefaultPrinter(res.defaultPrinter);
        setPrintDriver(res.printerTypeForDriver);
        setSupportLotLoc(res.supportLotLocation);
        setSupportColor(res.supportColor);
        setSupportStock(res.supportStockNo);
        setSupportLivePosting(res.livePosting);
        setPrintId(res.printId);
        setDealership(res.dealership);
        setDealershipName(res.dealership.dealerShipName);
        setIsLoading(false);
    }, [Did])

    const jspmWSStatus = () => {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
            return false;
        } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
            return true;
        } else if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
            return false;
        }
    }

    useEffect(() => {
        setIsLoading(true);
        JSPM.JSPrintManager.license_url = process.env.REACT_APP_SERVER_URL + "JSPM/LicenseV7";
        JSPM.JSPrintManager.auto_reconnect = true;
        JSPM.JSPrintManager.start();
        setTimeout(() => {
            const isJSPMInstalled = jspmWSStatus();

            if (!isJSPMInstalled) {
                setError({ code: 3, message: 'No Driver found' });
                setShowNotification(true);
            } else {
                fetchData();
            }
        }, 2000);

        const stopJSPrintManager = () => {
            if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
                JSPM.JSPrintManager.stop();
            }
        };

        window.addEventListener('beforeunload', stopJSPrintManager);

        return () => {
            window.removeEventListener('beforeunload', stopJSPrintManager);
        };
    }, [fetchData])

    const sendData = async () => {
        let res;
        let data = {
            "defaultPrinter": defaultPrinter,
            "dealership": dealership,
            "printerTypeForDriver": printDriver,
            "supportLotLocation": supportLotLoc,
            "supportStockNo": supportStock,
            "supportColor": supportColor,
            "livePosting": supportLivePosting,
            "printId": printId
        }

        if (printId === 0) {
            res = await GetModal('POST', 'DealerShip/PreferencesCreate?dealerIdSel=' + Did + '-Preferences', data);
            if (res === "Success") {
                sendResponse(0, 'Preferences created & configured successfully', 'Success')
            } else {
                sendResponse(1, 'Failed to create & configure the Preferences', 'Failure')
            }
        } else {
            res = await GetModal('POST', 'DealerShip/PreferencesEdit?dealerIdSel=' + Did + '-Preferences', data);
            if (res === "Success") {
                sendResponse(0, 'Preferences created & configured successfully', 'Success')
            } else {
                sendResponse(1, 'Failed to create & configure the Preferences', 'Failure')
            }
        }
    }

    const toggleCheckBoxes = (cbName: string) => {
        if (cbName === "lotLocation") {
            setSupportLotLoc(!supportLotLoc)
        } else if (cbName === "color") {
            setSupportColor(!supportColor)
        } else if (cbName === "livePosting") {
            setSupportLivePosting(!supportLivePosting)
        } else if (cbName === "stock") {
            setSupportStock(!supportStock)
        }
    }

    const downloadFile = (driverName: any) => {
        const link = document.createElement("a");
        if (driverName === "TSC") {
            link.href = "/assets/TSCDrivers.zip"
        } else {
            link.href = "/assets/ZebraDrivers.zip"
        }
        link.download = driverName + '.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
    }

    const fetchPrinters = useCallback(async () => {
        if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open && !fetchPrintersRef.current) {
            let printerNames: Printer[];
            printerNames = await JSPM.JSPrintManager.getPrinters() as Printer[];
            setPrinters(printerNames);
            fetchPrintersRef.current = true; // Mark fetchPrinters as called
        }
    }, []);

    if (!fetchPrintersRef.current) {
        fetchPrinters();
    }

    const handleOk = () => {
        setShowNotification(false);
        JSPM.JSPrintManager.stop();
        sendResponse(2, `Downloading jsPrintManager 
            After installing please refresh and try again`, "Info");
        DownloadJSPMDriver();
    }

    const handleCancel = () => {
        setShowNotification(false);
        JSPM.JSPrintManager.stop();
        sendResponse(2, "Print cancelled as user didnt want to download the driver", "Info");
    }

    return (
        <div>
            {isLoading ? (
                <div className="flex justify-center items-center">
                    <div className="loader1"></div>
                </div>
            ) : (
                <div>
                    <div className="modalTop-body mt-3">
                        <p className="sampleLabel">Dealers Preferences</p>
                        <hr className="mt-2" />
                    </div>
                    <div className="modal-body">
                        <div className="grid grid-cols-2 gap-2 mt-2">
                            <p className="sampleLabel">Please select the print manager</p>
                            <div>
                                <input type="radio" name="printManager" id="JSPM" value={0} checked={printManager === 0} onChange={handelPrintManagerChange} />&nbsp;
                                <label htmlFor="JSPM" className="sampleLabel">JSPM</label> &nbsp;&nbsp;&nbsp;
                                <input type="radio" name="printManager" id="Zebra" value={1} checked={printManager === 1} onChange={handelPrintManagerChange} />&nbsp;
                                <label htmlFor="Zebra" className="sampleLabel">Zebra</label>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 gap-2 mt-2 items-center justify-center">
                            <p className="sampleLabel">Dealership Name</p>
                            <input className="textInput" type="text" placeholder="Dealership Name" value={dealeshipName} readOnly />
                            <p className="sampleLabel">Default Printer</p>
                            <select className="sampleLabel" value={defaultPrinter} onChange={(e) => { setDefaultPrinter(e.target.value) }} >
                                {printers.map((option: any) => {
                                    return (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="grid grid-cols-3 gap-2 mt-3 items-center justify-between">
                            <p className="sampleLabel">Printer Type</p>
                            <div>
                                <input type="radio" name="printType" id="Zebra" value="Zebra" checked={printDriver === "Zebra"} onChange={handelPrintDriverChange} />&nbsp;
                                <label htmlFor="Zebra" className="sampleLabel">Zebra</label> &nbsp;&nbsp;&nbsp;
                                <input type="radio" name="printType" id="TSC" value="TSC" checked={printDriver === "TSC"} onChange={handelPrintDriverChange} />&nbsp;
                                <label htmlFor="TSC" className="sampleLabel">TSC</label>
                            </div>
                            <button className="button-6" onClick={() => { downloadFile(printDriver) }}>Download Driver</button>
                        </div>
                        <div className="grid grid-cols-4 gap-2 mt-3 items-center justify-center">
                            <div className="flex justify-start items-center">
                                <input type="checkbox" checked={supportLotLoc} onChange={() => { toggleCheckBoxes("lotLocation") }} />
                                <p className="sampleLabel pl-2">Support Lot Location</p>
                            </div>
                            <div className="flex justify-start items-center">
                                <input type="checkbox" checked={supportStock} onChange={() => { toggleCheckBoxes("stock") }} />
                                <p className="sampleLabel pl-2">Support Stock Number</p>
                            </div>
                        </div>
                        <div className="grid grid-cols-4 gap-2 mt-3 items-center justify-center">
                            <div className="flex justify-start items-center">
                                <input type="checkbox" checked={supportColor} onChange={() => { toggleCheckBoxes("color") }} />
                                <p className="sampleLabel pl-2">Support Color</p>
                            </div>
                            <div className="flex justify-start items-center">
                                <input type="checkbox" checked={supportLivePosting} onChange={() => { toggleCheckBoxes("livePosting") }} />
                                <p className="sampleLabel pl-2">Live Posting</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="flex justify-around items-center mt-4">
                            <button className="button-6" onClick={sendData}>Save</button>
                            {/* <button className="button-6">Cancel</button> */}
                        </div>
                    </div>
                </div>
            )}
            {error?.code === 3 ? (
                <NotifyComponent title="Printer Driver not found" message={`Printer driver not found!.
                Do you want to download the driver ?`} onOk={handleOk} onCancel={handleCancel} show={showNotification} />
            ) : null}
        </div>
    )
}

export default Preferences
