import { makeAPIRequest } from "./makeRequest";

const GetUsersList = () => {
    let config;
    config = {
        method: 'get',
        url: process.env.REACT_APP_SERVER_URL + 'user/getusers',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    try {
        let res = makeAPIRequest(config);
        return res
    } catch (err) {
        console.log(err)
    }
}

export default GetUsersList