import { makeAPIRequest } from "./makeRequest";

export const UpdateUserDevices = (data: any) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'UserDevices/DisableDevice?userDevice=' + JSON.stringify(data),
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}