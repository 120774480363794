import React, { useState } from 'react';

const CookieAcceptance: React.FC = () => {
    const [accepted, setAccepted] = useState<boolean>(false);

    const acceptCookies = () => {
        // Set a cookie to indicate that the user has accepted cookies
        document.cookie = 'cookiesAccepted=true; max-age=31536000'; // Expires in 1 year
        setAccepted(true);
    };

    const isCookiesAccepted = () => {
        // Check if the cookie indicating acceptance exists
        return document.cookie.split(';').some((cookie) => cookie.trim().startsWith('cookiesAccepted='));
    };

    // Check if cookies have already been accepted
    if (isCookiesAccepted()) {
        return null; // Hide the component if cookies have been accepted
    }

    return (
        <div>
            <div className="flex justify-center items-center">
                <p className="text-sm md:text-base text-white">
                    This website uses tracking cookies to improve user experience.
                </p>
                &nbsp;&nbsp;&nbsp;
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded text-sm md:text-base" onClick={acceptCookies}>
                    I Understand
                </button>
            </div>
        </div>
    );
};

export default CookieAcceptance;
