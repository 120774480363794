import { makeAPIRequest } from "./makeRequest"

export const signIn = (username: any, pass: any, source: any) => {
    let config = {
        method: 'post',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/CreateTokenWin',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            "Username": username,
            "Password": pass,
            "loginSource": source
        }
    }

    try {
        let res = makeAPIRequest(config)
        return res
    } catch (err) {
        console.log(err)
    }
}