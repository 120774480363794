import { Outlet, useLocation } from "react-router-dom";
import "./layoutStyles.css";
import CookieAcceptance from "../utils/cookieAcceptance";

const AuthLayout = () => {
    const location = useLocation();
    const isContactUsPage = location.pathname === '/contactus';

    return (
        <div className="main flex flex-col justify-between">
            <header className="header flex h-20 items-center justify-between px-5">
                <div className="logo w-1/4 md:w-1/5 lg:w-1/6">
                    {/* Your logo component or image goes here */}
                </div>

                {isContactUsPage ? (
                    <a href="/" className="customLinkBtn text-sm md:text-base">
                        Login
                    </a>
                ) : (
                    <div className="contactContainer flex items-center">
                        <p className="text-sm md:text-base text-white">Need help ?</p> &nbsp;&nbsp;&nbsp;
                        <a href="/contactus" className="customLinkBtn text-sm md:text-base">
                            Contact US
                        </a>
                    </div>
                )}

            </header>
            <main className="mb-auto">
                <div className="mx-auto h-full">
                    <Outlet />
                </div>
            </main>
            <footer className="w-full h-auto bg-gray-200 py-2 static bottom-0 footer">
                <CookieAcceptance />
            </footer>
        </div>
    )
}

export default AuthLayout