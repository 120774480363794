import React from 'react';
import './styles.css'
import DmsConnectivity from '../pages/dms_connectivity';
import LabelConfig from '../pages/label_config';
import Preferences from '../pages/preferences';
import EnableQR from '../pages/enableQR';
import AddVehicles from '../pages/addVehicle';
import { AlertsData } from '../pages/dashboard';
import AddScannedVehicle from '../pages/addScannedVehicles';
import ReportSummary from '../pages/reportSummary';
import SaveGridModal from '../pages/saveGrid';
import ClearGrid from '../pages/clearGrid';
import PrintTemplateSelector from '../pages/printTemplateSelector';
import RecentDownloadsModal from '../pages/recentDownloads';
import InventorySelection from '../pages/inventorySelection';
import ColumnChooser from '../pages/columnChooser';
import LotLocationChange from '../pages/lotLocation';
import UserForms from '../pages/userForms';
import AddendumVehicleForm from '../pages/AddendumForm';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    onAlert: AlertsData;
    onHideData: any
}

const CommonModal: React.FC<ModalProps> = ({ isOpen, onClose, title, onAlert, onHideData }) => {
    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className={title === "DMS Connectivity" || title === "Preferences" || title === "Recent Downloads" ? "modal-content w-[70%]" : title === "Print Data" ? "modal-content w-[40%]" : title === "columnChooser" ? "modal-content w-[30%] h-[40%] overflow-y-scroll" : title === "User Form" ? "modal-content w-[60%] h-[70%] overflow-y-scroll" : "modal-content w-[30%]"}>
                <div className="top-container">
                    <span className="close" onClick={onClose}>
                        &times;
                    </span>
                    <p className="headerTitle capitalize">{title}</p>
                </div>
                <hr />
                {title === "DMS Connectivity" ? (
                    <DmsConnectivity onAlertChnage={onAlert} />
                ) : title === "Label Request Information" ? (
                    <LabelConfig onAlertChnage={onAlert} />
                ) : title === "Preferences" ? (
                    <Preferences onAlertChnage={onAlert} />
                ) : title === "Download Label Print Support" ? (
                    <div></div>
                ) : title === "User List" ? (
                    <div />
                ) : title === "Enable QR-Code" ? (
                    <EnableQR onAlertChnage={onAlert} />
                ) : title === "Vehicle Status" ? (
                    <div />
                ) : title === "Add Vehicles" ? (
                    <AddVehicles onAlertChnage={onAlert} />
                ) : title === "scanVehicles" ? (
                    <AddScannedVehicle onAlertChnage={onAlert} />
                ) : title === "Report Summary" ? (
                    <ReportSummary />
                ) : title === "saveGrid" ? (
                    <SaveGridModal onAlertChnage={onAlert} />
                ) : title === "clearGrid" ? (
                    <ClearGrid onAlertChnage={onAlert} />
                ) : title === "Print Data" ? (
                    <PrintTemplateSelector onAlertChnage={onAlert} />
                ) : title === "Recent Downloads" ? (
                    <RecentDownloadsModal />
                ) : title === "Select Inventory" ? (
                    <InventorySelection onAlertChnage={onAlert} onClose={onClose} />
                ) : title === "columnChooser" ? (
                    <ColumnChooser onHideDataChange={onHideData} />
                ) : title === "changeLotLocation" ? (
                    <LotLocationChange onAlertChnage={onAlert} />
                ) : title === "User Form" ? (
                    <UserForms />
                ) : title === "Addendum" ? (
                    <AddendumVehicleForm onClose={onClose} onAlertChnage={onAlert}/>
                ) : (<div></div>)}
            </div>
        </div>
    );
};

export default CommonModal