import { makeAPIRequest } from "./makeRequest";

const GetUserDevices = (id: number) => {
    let config

    config = {
        method: 'get',
        url: process.env.REACT_APP_SERVER_URL + 'UserDevices/GetData?dealerID=' + id,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config)
    return res;
}

export default GetUserDevices