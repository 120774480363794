import { makeAPIRequest } from "./makeRequest";
let config

export const GetFileNames = (userId: number) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'Vehicle/FileNamesForInvoice?dealerid=' + userId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const GetInvoice = (vin: string, userId: number, fileName: string) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'Vehicle/VehicleFormDetails?vehicleID=' + vin + '&dealerid=' + userId + '&fileName=' + fileName,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}

export const PrintInvoice = (vin: string, userId: string, printData: string, fileName: string) => {
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'Vehicle/VehicleFormDetailsFile?vehicleID=' + vin + '&dealerid=' + userId + '&paramContent=' + printData + '&fileName=' + fileName,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}