import { makeAPIRequest } from "./makeRequest";

export const GetLoginCountData = () => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/UpdateNewUILoginCount',
        header: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}