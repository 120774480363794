import { makeAPIRequest } from "./makeRequest";

const ExportData = (id: number, data: string, report: string) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'Vehicle/ExportCSV?dealId='+ id + '&vehicleDetails=' + data + '&reportType=' + report,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    try {
        let res = makeAPIRequest(config);
        return res
    } catch (err) {
        console.log(err)
    }
}

export default ExportData