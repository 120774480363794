import { makeAPIRequest } from "./makeRequest";

const Print = (type: string, id: number, vin: string, fileName: string) => {
    let config
    if (type === 'getTemplateList') {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/GetFileNames?dealid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    } else if (type === "clearPrint") {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/ClearPrintRecord?vins='+ vin + '&dealerid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }
    } else if (type === "clearQR") {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/ClearQRScans?vins='+ vin + '&dealerid=' + id,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }
    } else {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/GoToPrint?vin=' + id + ',' + vin + ',' + fileName,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    let res = makeAPIRequest(config)
    return res
}

export default Print