import React, { useState, useEffect } from "react";
import './styles.css'
import { signIn } from "../api/login";
import useToken from "../hooks/useToken";
import { useNavigate } from "react-router-dom";
import Alert from "../components/alert";
import { GetLoginCountData } from "../api/getLoginCount";
import NotifyComponent from "../components/notifyComponent";

const Login = () => {
	let [username, setUsername] = useState('');
	let [pass, setPass] = useState('');
	let [errorCode, setErrorCode] = useState<number>();
	let [errorMessage, setErrorMessage] = useState('');
	let [showModal, setShowModal] = useState(false);
	const [newApp, setNewApp] = useState(true)
	const { logIn } = useToken();
	const nav = useNavigate();

	const login = async (e: any) => {
		e.preventDefault();
		try {
			let result = await signIn(username, pass, 'new')
			if (result.token) {
				if (result.role === "ScanningAgent") {
					setErrorCode(1);
					setErrorMessage('Scanning Agents cannot login to Website');
				} else {
					logIn({
						userName: result.username,
						accessToken: result.token,
						role: result.role,
						dealerId: result.dealerId,
						dealerCode: result.dealercode
					})
					sessionStorage.setItem("dealerId", result.dealerId);
					sessionStorage.setItem("userName", result.username);
					sessionStorage.setItem("dealercode", result.dealercode);
					sessionStorage.setItem("dealershipName", result.dealershipName);
					sessionStorage.setItem("email", result.email);
					getLogincount();
				}
			} else {
				setErrorCode(1);
				setErrorMessage(result.message)
			}
		} catch (error: any | undefined) {
			if (error) {
				setErrorCode(1);
				setErrorMessage("Something went wrong, Please try again later");
			}
		}
	}

	const getLogincount = async () => {
		let res = await GetLoginCountData();
		if (res === 1) {
            setShowModal(true); // Show notify component for first-time users
        } else if (res === 2) {
            nav("/dashboard/feedback"); // Navigate to feedback for second login
        } else {
            nav("/dashboard"); // Navigate to dashboard for all other cases
        }
	}

	function getCookie(name: any) {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++) {
			let cookie = cookies[i].trim();

			if (cookie.startsWith(name + '=')) {
				return cookie.split('=')[1];
			}
		}
	}

	const toggleSwitch = () => {
		setNewApp(!newApp);
		window.open(process.env.REACT_APP_OLD_URL, '_self');
	}

	useEffect(() => {
		let token = getCookie('accessToken');
		if (token) {
			let dealerId = getCookie('DealerId');
			let dealerId1 = getCookie('dealerId')
			let userName = getCookie('userName');
			if (dealerId1) {
				sessionStorage.setItem("dealerId", dealerId1);
			}
			if (dealerId && userName) {
				sessionStorage.setItem("dealerId", dealerId);
				sessionStorage.setItem("userName", userName);
				nav('/dashboard');
			}
		}
	}, [nav])

	const isFormValid = username.trim() !== '' && pass.trim() !== '';

	const handleOkClicked = () => {
        setShowModal(false);
		window.open('/dashboard/demoVideo', '_blank');
        nav('/dashboard');
    };

    const handleCancelClicked = () => {
        setShowModal(false);
        nav('/dashboard');
    };

	return (
		<div className="App flex justify-center items-center">
			<div className="glass1 p-5 w-full max-w-md">
				<h1 className="mb-2 text-md sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl font-bold leading-none tracking-tight text-gray-900 dark:text-white text-center">
					Welcome To <i>Vehicle Wizard!</i>
				</h1>
				<p className="text-base sm:text-sm md:text-sm lg:text-md font-medium text-gray-900 dark:text-white text-center">
					Please sign in to your account
				</p>

				<form className="p-3" onKeyDown={(e) => {
					if (e.key === 'Enter') {
						login(e);
					}
				}}>
					<label htmlFor="website-admin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left">
						Username
					</label>
					<div className="flex">
						<span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-transparent dark:text-gray-400 dark:border-gray-600">
							<svg
								className="w-6 h-6 text-gray-500 dark:text-white"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
							</svg>
						</span>
						<input
							type="text"
							id="website-admin"
							className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="John Doe"
							onChange={(e) => setUsername(e.target.value)}
						/>
					</div>

					{/* Password container */}
					<label htmlFor="website-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left mt-3">
						Password
					</label>
					<div className="flex">
						<span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border rounded-e-0 border-gray-300 rounded-s-md dark:bg-transparent dark:text-gray-400 dark:border-gray-600">
							<svg
								className="w-6 h-6 text-gray-800 dark:text-white"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 16 20"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M11.5 8V4.5a3.5 3.5 0 1 0-7 0V8M8 12v3M2 8h12a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1Z"
								/>
							</svg>
						</span>
						<input
							type="password"
							id="website-password"
							className="rounded-none rounded-e-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="&Ay8SdpBdK$YpH"
							onChange={(e) => setPass(e.target.value)}
						/>
					</div>

					<div className="loginBtnContainer mt-2">
						<button className="loginButton" disabled={!isFormValid} id="submit-btn" onClick={(e) => {
							login(e)
						}}>
							Lets Go!
						</button>
					</div>
				</form>

				<div className="flex justify-between items-center max-w-[80%] pl-5 mt-2">
					<div>
						<label className="relative inline-flex cursor-pointer items-center">
							<input id="switch-2" type="checkbox" className="peer sr-only" checked={newApp} onChange={toggleSwitch} />
							<label htmlFor="switch-2" className="hidden"></label>
							<div className="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-300 peer-checked:after:translate-x-full peer-focus:ring-green-300"></div>
						</label>
					</div>
					<div>
						<p className="text-sm">Swipe left for old application</p>
					</div>
				</div>

				<div className='bottomContainer mt-3'>
					<div className="passwordContainer flex justify-center items-center">
						<a href="/forgotPassword" className='drop-shadow-lg text-center items-center text-graphite font-montega'>
							Forgot Password ?
						</a>
					</div>
					<div className='policyContainer'>
						<div className="text-center" style={{ fontFamily: 'Kalnia' }}>
							By signing in, you agree to the <a href="/termsAndConditions">Terms of Service</a> and <a href="https://scansolutions.com/privacy-policy.html">Privacy Policy</a>.
						</div>
					</div>
				</div>
			</div>
			{errorCode === 1 || errorCode === 0 ? (
				<div className="absolute top-0 right-0">
					<Alert code={errorCode} message={errorMessage} />
				</div>
			) : null}
			
			{showModal && (
                <NotifyComponent 
                    title="Want to watch demo Video" 
                    message={`Hi ${username}, We have created a new user experience!${'\n\n'}Would you like to see a demo video?`} 
                    onOk={handleOkClicked} 
                    onCancel={handleCancelClicked} 
                    show={showModal} 
                />
            )}
		</div>
	);
}

export default Login