import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck, faInfo, faWarning, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

interface AlertProps {
    code: number;
    message: string;
}

const Alert: React.FC<AlertProps> = ({ code, message }) => {
    let codeName = '';
    let codeIcon: any;
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, 3500); // Close after 3.5 seconds

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setShow(false);
    }

    if (!show) {
        return null;
    }

    switch (code) {
        case 0:
            codeName = 'Success'
            codeIcon = faCheck
            break;
        case 1:
            codeName = 'Error'
            codeIcon = faXmarkCircle
            break;
        case 2:
            codeName = 'Info'
            codeIcon = faInfo
            break;
        case 3:
            codeName = "Warning"
            codeIcon = faWarning
            break;
        default:
            break;
    }

    return (
        <div className={code === 0 ? (
            "p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
        ) : code === 1 ? (
            "p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
        ) : code === 2 ? (
            "p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
        ) : code === 3 ? (
            "p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400"
        ) : ("")} role="alert">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <FontAwesomeIcon icon={codeIcon} size="lg" /> &nbsp; &nbsp; &nbsp; &nbsp;
                    <p className="font-bold">{codeName}</p>
                </div>
                <div className="cursor-pointer">
                    <FontAwesomeIcon icon={faXmark} size="xl" onClick={handleClose} />
                </div>
            </div>
            <p className="block sm:inline">{message}</p>
        </div>
    )
}

export default Alert