import React from "react";
import { useNavigate } from "react-router-dom"

const AuthFailure = () => {
    const nav = useNavigate();
    return (
        <div className="flex flex-row min-h-screen justify-center items-center authFailureBG">
            <video autoPlay width="1000" height="750">
                <source src={require('../assets/Whoa_There.mp4')} type="video/mp4" />
                Your browser does not support HTML5 video.
            </video>
            <div className="bottomBtn">
                <button className="button-86" onClick={() => nav('/')}>Home</button>
            </div>
        </div>
    )
}

export default AuthFailure