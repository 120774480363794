import { makeAPIRequest } from "./makeRequest";

const GetContextReport = (id: number, vinsData: string, reportType: string) => {
    let config;

    if (reportType === "lotHistory1") {
        config = {
            method: 'GET',
            url: process.env.REACT_APP_SERVER_URL + 'LotLocationHistory/GetData?dealid=' + id + '&historyvins=' + vinsData,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    let res: any = makeAPIRequest(config);
    return res
}

export default GetContextReport