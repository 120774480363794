import { makeAPIRequest } from "./makeRequest";

export const ManualRefreshOptions = (userId: number) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/Manual_Refresh?dealerID=' + userId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res
}

export const CDKDownload = (userId: number) => {
    let config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'VehWizAPI/LastdownloadForCDK?dealer=' + userId,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config);
    return res;
}