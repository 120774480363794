import ExportData from "../api/exportData";

export const CSVDownloader = async (id: number, data: string, report: string) => {
    let printingStarted = false
    try {
        if (!printingStarted) {
            printingStarted = true
            let res = await ExportData(id, data, report);
            if (res) {
                const blob = new Blob([res], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', report + '.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        }
    } catch (err) {
        console.log(err)
    }
}