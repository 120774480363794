import { makeAPIRequest } from "./makeRequest";

const ForgotPasswordAPI = (data: string) => {
    let config
    config = {
        method: 'GET',
        url: process.env.REACT_APP_SERVER_URL + 'ResetPassword/ChangePassword?username=' + data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }

    let res = makeAPIRequest(config)
    return res
}

export default ForgotPasswordAPI